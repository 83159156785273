.MuiTextField-root {
    border: none !important;
    outline: none !important;
    border-radius: 10px;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
        border-radius: 6.51px !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    padding-top: 13px !important ;
    padding-bottom: 13px !important ;
}
.css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 10px !important;
}