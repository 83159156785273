:root {
  --theme: #00275A;
  --white: #ffffff;
  --header: 16px;
  --subheading: 14px;
  --content: 12px;
  --iconswidth: 16px;
  --iconsheight: 16px;
  --mainiconwidth: 1.25rem;
  --mainiconheight: 1.25rem;
  --themelight: #f3f7ff;
}
.submit__button {
  background-color: var(--theme) !important;
  color: var(--white) !important;
}
.button__overlay {
  border: 2px solid var(--theme) !important;
}
.appbar {
  background-color: var(--theme) !important;
}
.heading__color {
  color: var(--theme) !important;
}
.heading {
  font-size: var(--header) !important;
  font-family: "figtree", sans-serif !important ;
}
.sub__heading {
  font-size: var(--subheading) !important;
}
.content {
  font-size: var(--content) !important;
}
.icons__img {
  height: var(--iconsheight) !important;
  width: var(--iconswidth) !important;
}
.mainicon {
  height: var(--mainiconheight) !important;
  width: var(--mainiconwidth) !important;
}
.background__light {
  background-color: var(--themelight) !important;
}
.banner__title {
  color: var(--theme) !important;
}
.truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
.sticky__div {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
}
.textfield::placeholder {
  font-size: 16px;
}
.wrap {
  white-space: pre-wrap !important;
}
