.navbar__input {
  // display: none;
  // width: 300px;
  // height: 49px;
  // padding: 1rem 0;
  background-color: transparent;
  outline: none;
  border: none;
  overflow: hidden;
  color: white;
  width: 100%;
  // text-transform: capitalize;
  // padding-left: 1rem;
  font-size: 18px;
}

.search__bar {
  border: 1.5px solid white;
  border-radius:10px;

  // gap: 0.7rem;
  padding: 0 1rem;
  width: 350px;
  height: 38px;
  text-transform: capitalize;
  justify-content: center;
  transition: 0.2s width;
}
.search__bar:hover .navbar__input {
  display: flex;
}
.search__bar:hover {
  justify-content: start !important;
  // width: 380px;
  border-radius: 10px;
}
.navbar__input::placeholder {
  color: white;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  // font-family: ;
}
.line {
  width: 100%;
  height: 5px;
  background-color: white;
  border-radius: 7px;
}
.navbar__logo {
  margin-top: 1rem;
}
// .search__icon {
//   // position: absolute;
//   // top: 10px;
// }
.search__icon {
  // color: white;
  // /* position: absolute; */
  // top: 0;
  // /* position: absolute; */
  // left: 10px;
  // top: 10px;
  cursor: pointer;
  width: 22px !important;
  height: 22px !important;
}
.text__blue {
  color: #2a4b95;
  font-size: 36px;
  font-weight: 700;
  line-height: 44px !important;
}
.mentors__img {
  height: 390px;
}
.carousel-item {
  background-color: transparent;
}
.text__main {
  font-size: 32px !important;
  font-weight: 600 !important;
  font-family: "Open Sans", sans-serif !important;
}

.dataItem {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  color: black;
  margin-top: 0.8rem;
  gap: 1.2rem;
  margin-left: 1.5rem;
}
.dataItem:hover {
  color: black;
}
.dataItem__para {
  margin-left: 10px;
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
  font-family: "Open Sans";
}
.carousel__item {
  height: 490px !important;
}
.navbar__menu {
  position: relative;
  // z-index: 30000000000000;
}
.profile__float {
  position: absolute;
  // padding: 0 8px;
  width: 250px;
  left: -185px;
  background-color: white;
  top: 80px;
  border-radius: 4px;
  border: 0.735294px solid #d2d2d2;
  height: auto !important;
}

.profile__btn {
  border: 3.5px solid #2a4b95;
  border-radius: 20px;
  padding: 1px 0 !important;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  margin: 5px 1rem;
  margin-top: 0.5rem;
  color: black;
  font-family: Open Sans;
  cursor: pointer;
}
.profile__btn:hover {
  color: black;
}
.link__profile {
  color: black;
  cursor: pointer;
  // padding-left: 4px !important;
}
.link__profile:hover {
  color: black;
}
// .navbar__logout--button {
//   background-color: #1c3464;
//   padding-left: 0 !important;
//   padding: 0rem 1.2rem !important;
//   color: white;
//   border-radius: 0.25rem;
//   font-size: 0.85rem;
// }
// .navbar__logout--button {
//   outline: none;
//   background: #333;
//   border: none;
//   display: inline-block;
//   padding: 6px 18px;
//   color: #eee;
//   margin-right: 10px;
//   border-radius: 5px;
//   font-size: 12px;
//   cursor: pointer;
// }
.custom-ui {
  width: 480px;
}
@media (max-width: 1198px) {
  .navbar__input {
    width: 350px !important;
  }
}
@media (max-width: 998px) {
  .navbar__input {
    width: 300px !important;
  }
}
@media (max-width: 898px) {
  body {
    background-color: white !important;
  }
  .navbar__input {
    width: 100% !important;
    border-radius: 10px !important;
    border: none !important;
    background-color: rgba(118, 118, 128, 0.12);
    color: rgba(60, 60, 67, 0.6) !important;
  }
  .navbar__input::placeholder {
    color: rgba(60, 60, 67, 0.6) !important;
  }
  .search__icon {
    top: 10px;
  }
  .search__small {
    width: 25px !important;
    height: 25px !important;
    top: 10px;
  }
  .menu-icon {
    width: 35px;
    height: 35px;
    position: relative;
    z-index: 9999999999999999;
  }
  .navbar__show {
    z-index: 2;
  }
  .text__main {
    font-size: 1rem !important;
  }
  .profile__img {
    width: 66px !important;
    height: 66px !important;
  }
}
