.rounded {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px !important;
  background: white;
  overflow: hidden;
}
.textheading {
  color: black;
  font-size: 16px !important;
  font-weight: 700 !important ;
  line-height: 21.8px !important ;
  font-family: "Nunito", sans-serif !important ;
  padding: 0.7rem 0.8rem;
  background-color: #d4dbeb;
}
.booking__box {
  border-radius: 8px;
  color: white;
  padding: 1rem;
}

.custom-calendar {
  gap: 0 !important;
}
.rmdp-week .custom-calendar {
  gap: 0rem !important;
}
.custom-calendar.rmdp-wrapper .rmdp-week {
  gap: 0.5rem !important;
}
.custom-calendar.rmdp-wrapper .rmdp-day-picker {
  padding: 3px;
}
.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: rgb(179, 179, 179) !important;
}
.custom-calendar .rmdp-today .sd {
  background-color: transparent !important;
  /* color: black !important; */
}
.highlight-today {
  background-color: rgba(255, 1, 1, 0.1);
  color: rgba(255, 1, 1, 0.1);
  border-radius: 50%;
}

.bookingoverviewimg {
  width: 24px;
  height: 24px;
}
@media (max-width: 900px) {
  .custom-calendar.rmdp-wrapper .rmdp-week {
    gap: 1.6rem !important;
  }
}
