.chat__container {
  margin: 1rem 0;
  background-color: white;
  border-radius: 4px;
  height: 88vh;
}
.border {
  border: 2px solid black;
}
.sticky__div {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
}
.chat__fullcontainer {
  margin-top: 0.3rem;
  border-radius: 12px;
  /* min-height: 65px; */
  width: 100%;
  /* padding: 12px; */
  flex-direction: row !important;
  align-items: start !important;
}
.chat__singlecontainer {
  flex-direction: row !important;
  align-items: center !important;
  gap: 20px;
}
.chat__gridcontainer {
  padding: 0 !important;
}
.space-between {
  padding: 0 1.2rem;
}
.recievermsg {
  font-size: 14px;
  line-height: 21px;
  padding: 8px 16px 8px 16px;
  font-weight: 400;
  background-color: #f1f1f1;
  border-radius: 12px;
  max-width: 50%;
  /* max-width: 50%; */
  /* display: flex;
  flex-direction: column;
  align-self: center; */

  gap: 10px;
  /* width: 100%; */
}
.sendermsg {
  background-color: #615ef0 !important;
  color: white;
}
.scrollsmall::-webkit-scrollbar {
  width: 8px;
}
.scrollsmall::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scrollsmall::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.scrollsmall::-webkit-scrollbar-thumb:hover {
  background: #555;
}
