.stack__round {
  background-color: white;
  border: 0.742701px solid #d2d2d2;
  border-radius: 15px;
  overflow: hidden;
}
button {
  border: none;
  background-color: white;
  text-align: start;
  padding-left: 1.3rem !important;
  font-size: 20px;
  font-weight: 600;
  padding: 0.3rem 0;
}
.time__picker {
  border: none !important;
  outline: none !important;
  outline: none !important;
  width: 180px !important;
  margin-top: 0.3rem !important;
}
.react-time-picker__wrapper {
  border: 1px solid #2a4b95 !important;
  border-radius: 10px;
  padding: 0.4rem;
}
.ep-arrow {
  position: relative !important;
}

@media (max-width: 700px) {
  .stack__round {
    border: none !important;
  }
  .time__picker {
    width: auto !important;
  }
}
