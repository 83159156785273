/* .waveContainer {
  bottom: 0;
  left: 0;
  width: 400px;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.waves {
  position: relative;

  height: 100px;
  margin-bottom: -7px;
  min-height: 100px;
  max-height: 150px;
}

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
} */
.waveContainer {
  bottom: 0;
  left: 0;
  width: 400px;
  overflow: hidden;
  line-height: 0;
}

.waves {
  position: relative;
  height: 100px;
  width: 900px;
  margin-bottom: -7px;
  min-height: 100px;
  max-height: 150px;
}

.wave {
  animation: moveWave 6s ease-in-out infinite alternate;
  /* animation: moveWave 6s linear infinite; */
}

.wave1 {
  fill: #ffb6c1; /* Light pink */
}

.wave2 {
  fill: #4169e1; /* Dark blue */
  animation-delay: -2s;
}

@keyframes moveWave {
  from {
    transform: translateX(-40%);
  }
  to {
    transform: translateX(0);
  }
}
