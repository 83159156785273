/* @import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css"; */

/* @font-face {
  font-family: Arial Nova;
  src: url(../src/assets/font/ArialNova.woff2);
} */
@font-face {
  font-family: "Figtree";
  src: url(../src/assets/font/Figtree-Regular.woff2.ttf);
  /* src: url("../src/assets/font/Figtree-Regular.woff2") format("woff2"),
    url("../src/assets/font/Figtree-Regular.woff") format("woff"); */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Figtree";
  src: url(../src/assets/font/Figtree-Bold.ttf);

  /* src: url("../src/assets/font/Figtree-Bold.woff2") format("woff2"),
    url("../src/assets/font/Figtree-Bold.woff") format("woff"); */
  font-weight: bold;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* border: 2px solid black; */
  font-family: "Figtree", sans-serif;
}

body {
  margin: 0;
  font-family: "Figtree", sans-serif !important ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eceaea !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  text-decoration: none !important;
}

img {
  width: 100%;
  height: 100%;
}
a {
  text-decoration: none;
  color: white;
}
a:hover {
  color: white;
}
.MuiAccordion-rounded {
  border-radius: 15px !important ;
  border: 0.742701px solid #d2d2d2 !important ;
  padding: 0.7rem 0 !important;
  background-color: white;
  /* font-family: "Open Sans" !important; */
  font-size: 32px;
  font-weight: 600;
}
.top-line {
  height: 32px;
  border-radius: 4px;
  width: 4px;
  background-color: white;
  align-self: center;
}
.MuiContainer-maxWidthLg {
  max-width: 1240px !important;
}
.input__file {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  cursor: pointer;
  background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRljZkkRywYKrvWq89YZZ6bZFWoOu9_b_vCgA&usqp=CAU");
}
input[type="file"] {
  display: none;
}
.MuiButton-outlined {
  color: #2a4b95 !important;
  border: 2px solid #2a4b95 !important;
  font-family: "Open Sans" !important;
  border-radius: 37px !important;
  font-size: 20px !important;
}
.notifi__btn {
  width: 20px;
  height: 20px;
  outline: none !important;
  border: none !important;
}
html {
  scroll-behavior: smooth;
}
.search__results::-webkit-scrollbar {
  scrollbar-width: thin;
  width: 0px;
}
.date__scroll::-webkit-scrollbar {
  scrollbar-width: thin;
  width: 0px;
}
.roundedprofile {
  border-radius: 100%;
  overflow: hidden;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* width: 75px;
  height: 75px; */
}
.datepicker {
  width: 100% !important;
  border-radius: 4px !important ;
  height: 1.4375em !important;
  padding: 20.5px 14px !important;
  border: 1px solid rgb(133, 133, 133);
}
.css-hzn0oj-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 6px 0 !important;
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 0 !important;
}

.react-confirm-alert {
  font-family: "Nunito";
  min-width: 400px !important;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}
.react-confirm-alert h1 {
  font-size: 1.5rem;
}
.react-confirm-alert > button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}
.button__login {
  color: "white" !important;
}
.button__login:hover {
  background-color: #2a4b95;
  color: "white" !important;
}

.ratingspopup {
  width: 800px;
  font-family: "Nunito" !important;
  height: 60vh;
  overflow: auto;
}
.ratingspopup::-webkit-scrollbar {
  scrollbar-width: thin;
  width: 0px;
}
.rating__font {
  font-family: "Nunito" !important;
}
.cardbtn {
  padding-left: 8px !important;
}
.rmdp-header-values {
  color: "white" !important;
}
.Signin__img {
  /* background-image: url("https://s3.ap-south-1.amazonaws.com/mentorlink/placeholder/1666162194387_Group%2034722%20(1).png"); */
  background-repeat: no-repeat;
  background-size: contain;
  height: 100vh;
}
.css-19r6kue-MuiContainer-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.css-1ucuzbp-MuiStack-root {
  padding: 0 !important;
}
/* .css-j7qwjs {
  /* flex-direction: row !important; */
/*} */
.react-confirm-alert-overlay {
  z-index: 999999 !important;
}
.css-199x5u6-MuiStack-root {
  width: 100% !important;
}
.signin__form .MuiContainer-maxWidthLg {
  max-width: 100% !important;
}
.signin__form .css-1oqqzyl-MuiContainer-root {
  padding: 0 !important;
}
@media (max-width: 700px) {
  .modal__styles {
    width: 95% !important;
    padding: 10px 0 !important;
  }
  .css-qivjh0-MuiStepLabel-label {
    font-size: 10px !important;
  }
}
.mentor-card {
  transition: box-shadow 0.3s ease-in-out;
}

.mentor-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
