// .banner__title {
//   color: #2c72ff;
// }
.landing__title {
  font-size: 44px !important;
  line-height: 45px !important;
  font-weight: 600 !important;
}
.landing__subheading {
  font-size: 22px !important;
  line-height: 30px !important;
  color: #8F8F8F !important;
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}
.landing__heading {
  font-size: 32px !important;
  font-weight: 600 !important;
}
.landing__content {
  font-size: 22px !important;
  max-width:800px ;
}
.footer__section {
  padding-top: .8rem;
}

@media (max-width: 500px) {
  .landing__title {
    font-size: 26px !important;
    line-height: 26px !important;
  }
  .landing__subheading {
    font-size: 20px !important;
  }
  #alert-dialog-slide-title {
    font-size: 6px !important;
  }
}
@media (max-width: 700px) {
  #alert-dialog-slide-title {
    font-size: 16px !important;
  }
  #exploreallButton{
    font-size: 14px;
    height:'20px'
  }
}
