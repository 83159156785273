.banner__landing {
  background: linear-gradient(
      150deg,
      rgba(0, 0, 0, 1) 10%,
      rgba(43, 43, 43, 0.8802871490393032) 30%,
      rgba(255, 255, 255, 0) 100%
    ),
    url("../../../assets/landingPagebannner.png");
  width: 100%;
  min-height: 80vh;
  background-repeat: no-repeat;
  background-size: cover;
}
.terms a {
  color: black !important;
}

/* url("../../../assets/landingPagebannner.png"); */
