.choosen__one {
  background-color: #1c3464 !important;
  color: white !important;
}
.rmdp-shadow {
  box-shadow: none !important;
  border: 0.742701px solid #d2d2d2 !important;
  border-radius: 4.4562px;
}
.rmdp-header-values span {
  color: white !important;
}
.rmdp-header {
  background-color: #2c72ff !important;
  color: white !important;
  margin: 0 !important;
  height: 43px !important ;
}
.rmdp-header-values {
  color: white;
}
.rmdp-calendar {
  padding: 0 !important;
}
.rmdp-week {
  gap: 1.5rem !important;
}
.rmdp-day {
  font-size: 16px !important;
}
.rmdp-day-picker {
  padding: 13px;
}

.rmdp-arrow {
  border: solid !important ;
  border-width: 0 2px 2px 0 !important;
  height: 6px !important ;
}
.booking__placeholder {
  // width: 100%;
  // height: 100%;
  height: 100vh;
  width: 100%;
  background-image: url("https://s3.ap-south-1.amazonaws.com/mentorlink/placeholder/1666257522563_Group%2034779.png"),
    linear-gradient(180.02deg, rgba(42, 75, 149, 0) 0.01%, #2a4b95 98.91%);
  border-radius: 0.25rem;
  background-repeat: no-repeat;
  background-position: center 0;

  // background-position: 50% 100%;
}
.booking__placeholder::after {
  content: " ";
  position: absolute;
  background-image: linear-gradient(
    180.02deg,
    rgba(42, 75, 149, 0) 0.01%,
    #2a4b95 98.91%
  );
}
.rounded__corner {
  border-radius: 4.4562px;
  border: 0.742701px solid #d2d2d2;
  background-color: #f9f9f9;
  padding: 0.7rem 1.3rem;
}
.selectedtime__hover:hover {
  background-color: none !important;
  // color: black !important;
}
.coupons__rounded {
  border: 0.742701px solid #d2d2d2;
  border-radius: 2.4562px;
  background-color: white;
  //   padding: 1rem;
}
.divider {
  width: 1px;
  height: 30px;
  background-color: #fafafa;
}
.coupons__title {
  background-color: #fafafa;
  padding: 1rem;
}
.coupons__heading {
  font-size: 16px !important;
  font-weight: 700 !important;
}
.redeem .css-1x51dt5-MuiInputBase-input-MuiInput-input {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  height: 3em;
  padding-left: 1rem;
}
