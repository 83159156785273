.coupons__rounded {
  border: 0.742701px solid #d2d2d2;
  border-radius: 2.4562px;
  background-color: white;
  //   padding: 1rem;
}
.divider {
  width: 1px;
  height: 30px;
  background-color: #fafafa;
}
.coupons__title {
  background-color: #fafafa;
  padding: 1rem;
}
.coupons__heading {
  font-size: 16px !important;
  font-weight: 700 !important;
}
.redeem .css-1x51dt5-MuiInputBase-input-MuiInput-input {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  height: 3em;
  padding-left: 1rem;
}
